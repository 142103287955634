<template>
	<section id="portfolio" class="size-18">
		<b-row>
			<b-col md="12">
				<h2 class="mb-2 pb-1">Our Portfolio</h2>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12" id="portfolio-tab">
				<template v-for="(item, index) in tabs">
					<template v-if="permission[item.slug].read">
						<router-link :to="item.link" :key="item.index">
							<b-card class="d-flex mb-20">
								{{ item.name }}
								<feather-icon icon="ChevronRightIcon" size="24" color="#2E3A59" />
							</b-card>
						</router-link>
					</template>

				</template>
			</b-col>
		</b-row>
	</section>
</template>

<script>
export default {
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: {
				"Project": [],
				"Activity": [],
				"Study Visit": []
			},
			permissionProject: [],
			permissionActivity: [],
			permissionStudyVisit: [],
			tabs: [
				{
					name: 'Project Listing',
					slug: 'Project',
					link: '/admin/our-portfolio/project'
				},
				{
					name: 'Activity Listing',
					slug: 'Activity',
					link: '/admin/our-portfolio/activity-listing'
				},
				{
					name: 'Study Visit Listing',
					slug: 'Study Visit',
					link: '/admin/our-portfolio/study-visit'
				},
			]
		}
	},
	created() {
		this.checkPermission2(this.userData, "Project");
		this.checkPermissionOurPortfolio2(this.userData, "Activity", null, "Activity");
		this.checkPermissionOurPortfolio2(this.userData, "Study Visit", null, "Study Visit");
	},
	methods: {
		checkPermissionOurPortfolio2(userData, namePage, id = null, category = "Project") {
			if (userData.role.role_name === "Staff" || userData.role.role_name === "Management") {
				var fields = {
					id: id,
					category: category
				}
				this.$store.dispatch('auth/checkPermisionOurPortfolio', fields)
					.then(response => {
						this.permission[category] = response.data.data;
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				var action = [];
				userData?.permission.map(function (value) {
					if (value.module === namePage) {
						action = value.actions
					}
				})
				this.permission[category] = action;
			}
		},
		checkPermission2(userData,namePage){
            var action = [];
            userData?.permission.map(function (value) {
                if(value.module === namePage){
                    action = value.actions
                }
            })
            this.permission[namePage] = action;
        },
		
	}
};

</script>

<style lang="scss">
#portfolio-tab .card .card-body {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#portfolio-tab a {
	color: #000000;
}

#portfolio-tab a:hover {
	color: #8471FF;
}
</style>

